import React from "react";
import { Hero, Bids, Top_collection, Tranding_category, NewseLatter } from "../../components/component";
import Meta from "../../components/Meta";
import Testimonial from "../../components/testimonial/Testimonial";
import Characters from "../../components/characters";
import Statistic from "../../components/promo/statistic";
import Statistic_promo_2 from "../../components/promo/statistic_promo_2";
import Intro_video from "../../components/intro_video";

const Home_1 = () => {
  return (
    <main>
      <Meta title="mtion.xyz" />
      <Hero />
      {/* <Intro_video /> */}
      {/* <Characters /> */}

      {/* <Statistic /> */}
      {/* <Statistic_promo_2 /> */}
      {/* <Testimonial /> */}
    </main>
  );
};

export default Home_1;
